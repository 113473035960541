import Vue from 'vue'
import VueRouter from "vue-router";

import loginVue from '../components/loginWeb.vue'
import IndexWeb from '../components/IndexWeb.vue'
import OrderManager from '../components/OrderManager.vue'
import HomeWeb from '../components/HomeWeb.vue'
import MainWeb from '../components/MainWeb.vue'
import LogisticsWeb from "@/components/LogisticsWeb.vue";
import OrderManagerOffline from "@/components/OrderManagerOffline.vue";
import OrderManagerOfflineCustom from "@/components/OrderManagerOfflineCustom.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: loginVue
    },
    {
        path: '/IndexWeb',
        name: 'index',
        component: IndexWeb,
        children:[
            {
                path:'/HomeWeb',
                name:'home',
                meta:'首页',
                component:HomeWeb
            },
            {
                path:'/OrderManager',
                name:'orderManager',
                meta:'订单管理',
                component:OrderManager
            },
            {
                path:'/mainWeb',
                name:'mainWeb',
                meta:'权限管理',
                component: MainWeb
            },
            {
                path:'/logisticsWeb',
                name:'mainWeb',
                meta:'物流单管理',
                component: LogisticsWeb
            },
            {
                path:'/OrderManagerOffline',
                name:'OrderManagerOffline',
                meta:'线下订单管理',
                component: OrderManagerOffline
            },
            {
                path:'/OrderManagerOfflineCustom',
                name:'OrderManagerOfflineCustom',
                meta:'线下订单查看',
                component: OrderManagerOfflineCustom
            }
            
        ]
    }
]

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

const router = new VueRouter({
    mode: 'history',
    routes
})

// 导航守卫
// 参数1 : to 目标路由对象
// 参数2 : from 来源路由对象
// 参数3 : next() 下一步
router.beforeEach((to, from, next) => {
    // 1. 判断是不是登录页面
    // 是登录页面
    if(to.path === '/') {
        next()
    } else {
        // 不是登录页面
        // 2. 判断 是否登录过
        let user = sessionStorage.getItem('CurUser')
        if (user != undefined && user != null && user != ''){
            next()
        }else{
            next('/')
        }
    }
})

export default router