
<script>
import DateUtils from "@/components/DateUtils.vue";
export default {
  name: 'HomeWeb',
  comments: {DateUtils},
  data() {
    return {
      user: {}
    }
  },
  computed: {

  },
  methods: {
    init() {

      let currentUser = JSON.parse(sessionStorage.getItem("CurUser"))
      this.user = currentUser.data;

    }
  },
  created() {
    this.init();
  }
}
</script>

<template>
  <div>

    <el-descriptions
     :title="'欢迎你！'+user.username"
    direction="vertical"
    column="2"
    border >

    <el-descriptions-item label="用户名"> <i class="el-icon-s-custom"></i> {{user.username}}</el-descriptions-item>
    <el-descriptions-item label="权限">
      <el-tag :type="user.status ==='1' ? 'danger': user.status ==='0' ? 'success' : 'primary'"
            disable-transitions>{{user.status === '1'? '禁用' : user.status === '0'? '启用' : user.status === '7' ? '用户' : '管理员'}}</el-tag>
          </el-descriptions-item>
    </el-descriptions>


  </div>
</template>


<style scoped>

</style>