<script setup>

</script>

<template>
  <div style="height: 100%; width: 100%">
    <div style="margin-bottom: 5px">
      <el-input v-model="account" placeholder="请输入帐号" suffix-icon="el-icon-search" style="width: 200px;"
                @keyup.enter.native="loadPost"></el-input>
      <el-select v-model="status" clearable placeholder="请选择权限" style="margin-left: 5px;">
        <el-option
            v-for="item in statuses"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-button style="margin-left: 5px;" type="success" @click="loadPost">查询</el-button>
      <el-button type="danger" @click="resertQuery">重置</el-button>
      <el-button type="primary" @click="addUser">新增</el-button>
    </div>
    <el-table :data="tableData" style="height: 100%;"
              :header-cell-style="{background: '#f3f6fd', color: '#555'}"
              border
    >
      <el-table-column prop="account" label="帐号" width="100">
      </el-table-column>
      <el-table-column prop="username" label="用户名" width="180">
      </el-table-column>
      <el-table-column prop="companyName" label="公司名"  width="180">
      </el-table-column>
      <el-table-column prop="address" label="公司地址"  width="180" >
      </el-table-column>
      <el-table-column prop="registerDate" label="注册时间"  width="180">
      </el-table-column>
      <el-table-column prop="lastLoginDate" label="最后登录时间"  width="180">
      </el-table-column>
      <el-table-column prop="status" label="权限"  width="100">
        <template slot-scope="scope">
          <el-tag
              :type="scope.row.status ==='1' ? 'danger': scope.row.status ==='0' ? 'success' : 'primary'"
              disable-transitions>
              {{scope.row.status === '1'? '禁用' : scope.row.status === '0'? '启用' : scope.row.status === '7' ? '线下单用户' : '管理员'}}
            </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="operate" label="操作"  width="180">
        <template slot-scope="scope">
          <el-button size="small" type="success" @click="updateUser(scope.row)">编辑</el-button>
          <el-popconfirm size="small" type="danger" title="确定删除吗?" @confirm="deleteUser(scope.row.id)" style="margin-left: 5px;">
            <el-button slot="reference" size="small" type="danger">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>


    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>

    <el-dialog
        title="提示"
        :visible.sync="centerDialogVisible"
        width="30%"
        center>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item prop="account" label="帐号">
          <el-input v-model="form.account"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item prop="username" label="用户名">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机号">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item prop="companyName" label="公司名称">
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
        <el-form-item prop="address" label="公司地址">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item prop="address" label="权限">
          <el-select v-model="form.status" placeholder="请选择权限">
            <el-option label="启用" value="0"></el-option>
            <el-option label="禁用" value="1"></el-option>
            <el-option label="管理员" value="2"></el-option>
            <el-option label="线下单用户" value="7"></el-option>

          </el-select>
        </el-form-item>
        <el-form-item prop="createTime" label="注册日期">
          <el-col :span="11">
            <el-date-picker
                v-model="form.createTime"
                type="datetime"
                placeholder="选择日期时间">
            </el-date-picker>
          </el-col>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="cancelForm">取 消</el-button>
    <el-button type="primary"  @click="saveUser">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
  import monment from "moment/moment";

  export default {
    name: "MainWeb",
    data() {
      return {
        tableData: [],
        pageSize: 5,
        pageNum: 1,
        total: 0,
        account: '',
        status: '',
        statuses: [{
          value: '1',
          label: '禁用'
        }, {
          value: '0',
          label: '启用'
        }, {
          value: '2',
          label: '管理员'
        }],
        centerDialogVisible: false,
        form:{
          id:'',
          account:'',
          username:'',
          password:'',
          address:'',
          companyName:'',
          createTime:'',
          status:'',
          phone:''
        }
      }
    },
    methods:{
      updateUser(row){
        console.log(row)
            this.form.id = row.id;
            this.form.account = row.account;
            this.form.username = row.username;
            this.form.password = row.password;
            this.form.address = row.address;
            this.form.companyName = row.companyName;
            this.form.createTime = row.createTime;
            this.form.status = row.status;
            this.form.phone = row.phone;
        this.centerDialogVisible = true
      },
      deleteUser(id){
        this.$axios.get(this.$httpUrl + '/main/deleteUser?id='+id).then(res=> res.data).then(res=>{
          if (res.code == 200) {
            this.centerDialogVisible = false
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.loadPost();
          }else {
            this.$message({
              message: '删除失败',
              type: 'error'
            });
          }
        })
      },
      cancelForm() {
        this.$refs.form.resetFields();
        this.centerDialogVisible = false
      },
      resetForm() {
        this.$refs.form.resetFields();
      },
      addUser() {
        this.centerDialogVisible = true
        this.$nextTick(()=>{
          this.resetForm()
        })
      },
      saveUser() {
        this.$axios.post(this.$httpUrl + '/main/saveUser',this.form).then(res=> res.data).then(res=>{
          if (res.code == 200) {
            this.centerDialogVisible = false
            this.$message({
              message: '添加成功',
              type: 'success'
            });
            this.loadPost();
          }else {
            this.$message({
              message: '添加失败',
              type: 'error'
            });
          }
        })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val}条`)
        this.pageSize = val
        this.pageNum = 1
        this.loadPost()
      },
      handleCurrentChange(val) {
        console.log(`当前页:${val}`)
        this.pageNum = val
        this.loadPost()
      },
      resertQuery() {
        this.status = '';
        this.account = ''
      },
      loadPost(){
        this.$axios.post(this.$httpUrl + '/main/getUserPageList',{
          pageNum:this.pageNum,
          pageSize:this.pageSize,
          param:{
            account:this.account,
            status:this.status
          }
        }).then(res=> res.data).then(res=>{
          res.data.records.forEach(item => {
            item.registerDate = monment(item.registerDate).format('YYYY-MM-DD HH:mm:ss')
            item.lastLoginDate = monment(item.lastLoginDate).format('YYYY-MM-DD HH:mm:ss')
          });
          this.tableData = res.data.records
          this.total = res.data.total
        })
      }
    },
    beforeMount() {
      // this.loadGet();
      this.loadPost();
    }
  }
</script>

<style scoped>

</style>