<script setup>

</script>

<template>
  <div style="display: flex; line-height: 60px;">
    <div style="margin-top: 8px;">
      <i :class="icon" style="font-size: 20px;" @click="collapse"></i>
    </div>
    <div style="flex: 1;text-align: center;font-size: 34px;">
      <span>中谊物流管理系统</span>
    </div>
    <el-dropdown>
      <span>{{ user.data.username}}</span>
      <i class="el-icon-arrow-down" style="margin-left: 15px"></i>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="toUser">个人中心</el-dropdown-item>
        <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<style scoped>

</style>

<script>
export default {
  name: "HeaderWeb",
  props: {
    icon: String
  },
  data() {
    return {
      user: JSON.parse(sessionStorage.getItem("CurUser"))
    }
  },
  methods: {
    toUser() {
      console.log("to_user")
      this.$router.push("/HomeWeb")
    },
    logout() {
      this.$confirm('您确定要退出吗？', {
        confirmButtonText: '确认',
        type: 'warning',
        center: true,//文字居中显示

      }).then(() => {
        this.$message({
          type: 'success',
          message: '退出登录成功'
        })
        this.$router.push("/")
        sessionStorage.clear()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    collapse() {
      this.$emit("doCollapse")
    },
    created() {
      this.$router.push('/HomeWeb')
    }
  }
}
</script>