<template>
  <div class="LoginBody">
    <div class="LoginDiv">

      <div class="Login-content">
        <el-form v-model="loginForm" label-width="100px" ref="loginForm">
          <el-form-item>
            <span style="text-align: center!important;color: white;margin-left: 20px; font-size: xx-large; font-weight: bolder">中谊物流管理系统</span>
          </el-form-item>
          <el-form-item prop="account">
            <div class="my-divider" >
              <div class="line" :style="{width: leftWidth}" ></div>
              <span class="label" style="width: auto;padding: 0 12px;text-align: center;white-space: nowrap;transform: translateY(-1px);">欢迎登录</span>
              <div class="line" :style="{width: rightWidth}"></div>
            </div>
            <el-input type="text" placeholder="请输入帐号" prefix-icon="el-icon-s-custom" v-model="loginForm.account" size="small"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" placeholder="请输入密码" v-model="loginForm.password" prefix-icon="el-icon-s-goods"
                      size="small" @keyup.enter.native="confirm"></el-input>
          </el-form-item>
          <el-form-item>
            <div>
              <el-button type="primary" @click="confirm" :isabled="confirm_disabled">确定</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>


    </div>
    <div class = "LoginDiv2">

    </div>
  </div>
</template>

<script>

export default {
  name: 'loginWeb',
  props: {
    // 文字
    label: {
      type: String,
      default: '欢迎登录'
    },
    // 文字位置，左 left，右 right，中 center
    contentPosition: {
      type: String,
      default: 'center'
    },
  },
watch: {
  contentPosition() {
    this.setLineWidth();
  }
},
  data() {
    return {
      leftWidth: '50%',
      rightWidth: '50%',
      confirm_disabled: false,
      loginForm: {
        account: '',
        password: ''
      }
    }
  },
  methods: {
    setLineWidth() {
      let p = this.contentPosition;
      switch (p) {
        case 'center': {
          this.leftWidth = '50%';
          this.rightWidth = '50%';
          break;
        }
        case 'left': {
          this.leftWidth = '10%';
          this.rightWidth = '90%';
          break;
        }
        case 'right': {
          this.leftWidth = '90%';
          this.rightWidth = '10%';
          break;
        }
      }
    },
    confirm() {
      let this_=this
      this.confirm_disabled = true;
      this.$axios.post(this.$httpUrl + '/main/login2', this.loginForm).then(res => {
        if (res.data.code == 200) {
          sessionStorage.setItem("CurUser", JSON.stringify(res.data))
          this_.$router.replace("/IndexWeb")
        } else {
          this_.confirm_disabled = false;
          this.$message({
            message: '校验失败，用户名或密码错误！',
            type: 'error'
          });
          return false;
        }
      })
    }
  },
  mounted() {
    this.setLineWidth();
  }
}

</script>
<style scoped>
.LoginBody {
  position: absolute;
  width: 100%;
  height: 100%;
  //background-color: #B3C0D1;
  background-image: url("../assets/picture3.jpeg");
  background-position: center;
  background-size: 100%;
}

.LoginDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  margin-left: -250px;
  width: 450px;
  height: 330px;
  //background: #fff;
  border-radius: 5%;
  z-index: 99;
}
.Login-content {
  width: 400px;
  height: 250px;
  position: absolute;
  top: 100px;
}
.LoginDiv2 {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
}
.el-button--small {
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 3px;
  width: 300px;
}
.my-divider {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items:center;
  margin: 15px 0;
  color:#c0c4cc;
  .line {
    background: #ffffff;
    height: 1px;
  }
}
</style>