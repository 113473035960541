<template>
  <el-container style="height: 100%; border: 1px solid #eee">
    <el-aside :width="aside_width" style="background-color: rgb(238, 241, 246);height: 100%; margin-left: -1px;">
      <AsideWeb :isCollapse="isCollapse"></AsideWeb>
    </el-aside>

    <el-container style="height: 100%">
      <el-header style="text-align: right; font-size: 12px;height: 100%; border-bottom: rgba(169,169,169,0.3) 1px solid;">
        <HeaderWeb @doCollapse="doCollapse" :icon="icon"></HeaderWeb>
      </el-header>

      <el-main style="height: 100%">
<!--      <MainWeb></MainWeb>-->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<style scoped>
.el-header {
  //background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}
.el-main {
  padding: 5px;
}
.el-aside {
  color: #333;
}
</style>

<script>


import AsideWeb from "@/components/AsideWeb.vue";
import HeaderWeb from "@/components/HeaderWeb.vue";

export default {
  name: "IndexMain",
  components: { HeaderWeb, AsideWeb},
  data() {
    return {
      isCollapse:false,
      aside_width:'200px',
      icon:'el-icon-s-fold'
    }
  },
  methods :{
    doCollapse() {
      this.isCollapse = !this.isCollapse
      this.isCollapse == true ? this.aside_width="64px" : this.aside_width = "200px"
      this.isCollapse == true ? this.icon="el-icon-s-unfold" : this.icon = "el-icon-s-fold"
    }
  },
  created() {
    this.$router.push("/HomeWeb")
  }
};
</script>