import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/global.css'
import axios from "axios";
import VueRouter from 'vue-router'
import router from './router';
import './style/icon/iconfont/iconfont.css'
import moment from 'moment';


const BASE_URL = process.env.NODE_ENV === 'development'
  ? 'https://localhost:9080' // 本地地址
  : 'https://zhimacode.cn:9080'; // 远程地址


Vue.prototype.$axios =axios;
Vue.prototype.$httpUrl= BASE_URL

Vue.use(VueRouter);
//  Vue.use(VueRouter);
Vue.use(ElementUI,{size:'small'});
Vue.config.productionTip = false

Vue.prototype.$formatDate = function(dateInput) {
  if (!dateInput) {
     return ""; 
  }
  return moment(dateInput).format('YYYY-MM-DD'); 
};

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
