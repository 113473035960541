<template>
  <el-menu
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      default-active="/HomeWeb"
      :collapse="isCollapse"
      :collapse-transition="false"
      router
      style="height: 100vh;">
    <el-menu-item index="/HomeWeb">
      <i class="el-icon-s-home"></i>
      <span slot="title">首页</span>
    </el-menu-item>
    <el-menu-item index="/OrderManager" v-if="user.status != 7">
      <i class="el-icon-s-flag"></i>
      <span slot="title">订单管理</span>
    </el-menu-item>
    <el-menu-item index="/MainWeb" v-if="user.status != 7">
      <i class="el-icon-s-opportunity"></i>
      <span slot="title">用户管理</span>
    </el-menu-item>
    <el-menu-item index="/LogisticsWeb" v-if="user.status != 7">
      <i class="el-icon-s-opportunity"></i>
      <span slot="title">物流单管理</span>
    </el-menu-item>
    <el-menu-item index="/OrderManagerOffline" v-if="user.status != 7">
      <i class="el-icon-s-flag"></i>
      <span slot="title">深国电物流管理</span>
    </el-menu-item>
    <el-menu-item index="/OrderManagerOfflineCustom" v-if="user.status == 7">
      <i class="el-icon-s-flag"></i>
      <span slot="title">深国电物流模块</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
  export default {
    name: "AsideWeb",
    data(){
      return {
        user: {}
      }
    },
    props:{
      isCollapse:Boolean
    },
    methods: {
      init() {
        let currentUser = JSON.parse(sessionStorage.getItem("CurUser"))
        this.user = currentUser.data;
      }
    },
    beforeMount() {
      this.init();
    }
  }
</script>

<style scoped>

</style>
