<script setup>

</script>

<template>
  <div style="height: 100%; width: 100%">
    <div style="margin-bottom: 5px;width: 100%">
      <el-input v-model="codeQry" placeholder="请输入运单号" suffix-icon="el-icon-search"
                style="width: 200px;margin-left: 10px"
                @keyup.enter.native="loadPost"></el-input>

      <el-select v-model="orderModel" clearable placeholder="请选择运单状态" style="margin-left: 30px;">
        <el-option
            v-for="item in orderModels"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-date-picker
          v-model="startTime"
          type="datetime"
          placeholder="选择开始时间"
          default-time="00:00:00" style="margin-left: 30px">
      </el-date-picker>

      <span>-</span>
      <el-date-picker
          v-model="endTime"
          type="datetime"
          placeholder="选择结束时间"
          default-time="00:00:00">
      </el-date-picker>


      <el-button style="margin-left: 30px;" type="primary" @click="queryList">查询</el-button>
      <el-button type="success" @click="resertQuery">重置</el-button>

      <el-button type="primary" @click="uploadFileDialogVisible = true">导入</el-button>
      <el-button type="primary" @click="exportExcel">导出模板</el-button>
    </div>
    <el-table :data="tableData" style="height: 100%; width: 100%"
              :header-cell-style="{background: '#f3f6fd', color: '#555'}"
              border
    >
      <el-table-column prop="code" label="运单号" width="100">
      </el-table-column>
      <el-table-column prop="goodsName" label="商品名" width="180">
      </el-table-column>
      <el-table-column prop="num" label="数量" width="180">
      </el-table-column>
      <el-table-column prop="trustCompany" label="发货公司" width="180">
      </el-table-column>
      <el-table-column prop="trustTime" label="委托时间" width="180">
      </el-table-column>
      <el-table-column prop="orderModel" label="运单状态" width="100">
        <template slot-scope="scope">
          <el-tag
              :type="scope.row.orderModel ==='1' ? 'success': scope.row.orderModel ==='3' ? 'warning' : scope.row.orderModel ==='4'? 'primary':'danger'"
              disable-transitions>
            {{
              scope.row.orderModel === '1' ? '发货' : scope.row.orderModel === '3' ? '配送中' : scope.row.orderModel === '4' ? '签收' : '异常'
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="operate" label="操作" width="180">
        <template slot-scope="scope">
          <el-button size="small" type="success" @click="orderDetail(scope.row)">详情</el-button>
          <el-button size="small" type="primary" @click="updateStatus(scope.row)">修改</el-button>
        </template>
      </el-table-column>


    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>

    <el-dialog
        title="提示"
        :visible.sync="centerDialogVisible"
        width="30%"
        center>
      <template>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="运单信息" name="first" :model="form">
            <el-descriptions class="margin-top" :column="3" :size="size" border>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  订单号
                </template>
                {{ form.code }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                  商品名称
                </template>
                {{ form.goodsName }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-location-outline"></i>
                  数量
                </template>
                {{ form.num }}
              </el-descriptions-item>
              <el-descriptions-item :model="form">
                <template slot="label">
                  <i class="el-icon-user"></i>
                  发货人
                </template>
                {{ form.trustMan }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                  发货人电话
                </template>
                {{ form.trustMobile }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-location-outline"></i>
                  发货公司
                </template>
                {{ form.trustCompany }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  发货地址
                </template>
                {{ form.trustAddr }}
              </el-descriptions-item>
              <br/>
              <el-descriptions-item :model="form">
                <template slot="label">
                  <i class="el-icon-user"></i>
                  收货人
                </template>
                {{ form.trustMan }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                  收货人电话
                </template>
                {{ form.trustMobile }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  收货地址
                </template>
                {{ form.trustAddr }}
              </el-descriptions-item>
            </el-descriptions>
          </el-tab-pane>
          <el-tab-pane label="物流信息" name="second" :model="form">
            <el-timeline>
              <el-timeline-item
                  v-for="(activity, index) in activities"
                  :key="index"
                  :icon="activity.orderModelStatus === '发货' ? 'iconfont icon-baoguo_fahuo_o' : activity.orderModelStatus === '配送' ? 'iconfont icon-yunshuzhongwuliu' : activity.orderModelStatus === '签收' ? 'iconfont icon-yiqianshou' : 'iconfont icon-yichangwuliu'"
                  type="primary"
                  size="larger"
                  :timestamp="activity.arrivalTime">
                <span style="font-weight: bolder;font-size: larger">{{ activity.orderModelStatus }}<br/></span>
                <span>{{ activity.orderModelName }}</span>
              </el-timeline-item>
              <el-timeline-item size="larger" icon="iconfont icon-shoujianren">{{ receiveRemark }}</el-timeline-item>
            </el-timeline>
          </el-tab-pane>
        </el-tabs>
      </template>
      <span slot="footer" class="dialog-footer">
  </span>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="uploadFileDialogVisible"
        :close-on-click-modal="false"
        :before-close="handleExcelDialogClose"
        width="50%"
        center>
      <el-upload
          class="upload-demo"
          ref="upload"
          :action="upLoadUrl"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-success="onSuccess"
          :on-error="submitUploadError"
          :file-list="fileList"
          :auto-upload="false" style="margin-left: 250px">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
        <div slot="tip" class="el-upload__tip">只支持提供的模板格式导入信息</div>
      </el-upload>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="updateCenterDialogVisible"
        width="30%"
        center>
      <el-form ref="form" :model="updateForm" label-width="80px">
        <el-form-item prop="code" label="运单号">
          <el-input v-model="updateForm.code" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item prop="orderModel" label="运单状态">
          <el-select v-model="updateForm.orderModel" placeholder="运单状态">
            <el-option label="异常" value="0"></el-option>
            <el-option label="发货" value="1"></el-option>
            <el-option label="运输中" value="2"></el-option>
            <el-option label="配送中" value="3"></el-option>
            <el-option label="签收" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="memo" label="状态名">
          <el-input v-model="updateForm.memo"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="cancelForm">取 消</el-button>
    <el-button type="primary" @click="updateOrderStatus">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import monment from 'moment'

export default {
  name: "OrderManager",
  props: {
    getTable: {
      type: Function,
      require: true
    },
  },
  data() {
    return {
      activities: [{
        orderModelStatus: '支持使用图标',
        timestamp: '2018-04-12 20:46',
        size: 'large',
        type: 'primary',
        icon: 'el-icon-more'
      }, {
        orderModelStatus: '支持自定义颜色',
        timestamp: '2018-04-03 20:46',
        type: 'primary',
      }, {
        orderModelStatus: '支持自定义尺寸',
        timestamp: '2018-04-03 20:46',
        size: 'large',
        type: 'primary'
      }, {
        orderModelStatus: '默认样式的节点',
        timestamp: '2018-04-03 20:46'
      }],

      upLoadUrl: this.$httpUrl + '/kkl/tms/webchat/orders/import',
      activeStep: '1',//当前激活的步骤索
      steps: [
        {orderModelStatus: '步骤一', orderModelName: '这是步骤一的描述'},
        {orderModelStatus: '步骤二', orderModelName: '这是步骤二的描述'},
        {orderModelStatus: '步骤三', orderModelName: '这是步骤三的描述'}
      ],
      receiveRemark: '',
      fileList: [],
      tableData: [],
      pageSize: 5,
      pageNum: 1,
      total: 0,
      orderModel: '',
      orderPicture: '',
      startTime: '',
      endTime: '',
      activeName: 'first',
      codeQry: '',
      size: '',
      orderModels: [{
        value: '1',
        label: '发货'
      }, {
        value: '3',
        label: '配送中'
      }, {
        value: '0',
        label: '异常'
      }, {
        value: '4',
        label: '签收'
      }],
      centerDialogVisible: false,
      updateCenterDialogVisible: false,
      uploadFileDialogVisible: false,
      form: {
        code: '',
        goodsName: '',
        num: '',
        trustMan: '',
        trustCompany: '',
        trustMobile: '',
        trustAddr: '',
        trustTime: '',
        receiveAddr: '',
        receiveMan: '',
        receiveMobile: '',
        receiveCompany: '',
        arrivalTime: ''
      },

      updateForm: {
        id: '',
        code: '',
        orderModel: '',
        memo: '',
        arrivalTime: ''
      }
    }
  },
  methods: {

    cancelForm() {
      // this.$refs.form.resetFields();
      this.updateCenterDialogVisible = false
    },
    resetForm() {
      // this.$refs.form.resetFields();
    },

    exportExcel() {
      window.open(this.$httpUrl + '/kkl/tms/webchat/orders/export')
    },
    handleExcelDialogClose(done) {
      this.$confirm('确认关闭？')
          .then(res => {
            this.$refs.upload.clearFiles() //清除上传文件对象
            done();
            console.log(res)
          })
          .catch(res => {
            console.log(res)
          });
    },
    onSuccess(res, file, fileList) {
      //显示添加成功的条数 并刷新
      this.$refs.upload.clearFiles() //清除上传文件对象
      this.uploadFileDialogVisible = false
      this.$message.success("成功导入 " + res.data.successNum + " 条订单")
      console.log(fileList, file)
      this.loadPost();
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    submitUploadSuccess() {
      this.getTable();
    },
    submitUploadError() {

    },
    updateStatus(row) {
      console.log(row)
      this.updateForm.code = row.code;
      this.updateForm.orderModel = row.orderModel;
      this.updateForm.memo = row.memo;
      this.updateForm.arrivalTime = row.arrivalTime;
      this.updateCenterDialogVisible = true
    },

    orderDetail(row) {
      this.centerDialogVisible = true
      this.form.code = row.code;
      this.form.goodsName = row.goodsName;
      this.form.num = row.num;
      this.form.trustMan = row.trustMan;
      this.form.trustCompany = row.trustCompany;
      this.form.trustMobile = row.trustMobile;
      this.form.trustAddr = row.trustAddr;
      this.form.trustTime = row.trustTime;
      this.form.receiveAddr = row.receiveAddr;
      this.form.receiveMan = row.receiveMan;
      this.form.receiveMobile = row.receiveMobile;
      this.form.arrivalTime = row.arrivalTime;
      this.form.receiveCompany = row.receiveCompany;
      this.queryOrderDetail(row);
    },
    queryOrderDetail(row) {
      console.log('=====' + row)
      this.$axios.get(this.$httpUrl + '/kkl/tms/webchat/orders/queryOrderDetail?code=' + row.code).then(res => res.data).then(res => {
        if (res.code == 200) {
          res.data.orderLogistics.forEach(item => {

            item.arrivalTime = monment(item.arrivalTime).format('YYYY-MM-DD HH:mm:ss')
            if (item.orderModel === '1') {
              item.orderModelStatus = '发货'
              item.orderModelName = '货物已发货，发往:' + row.receiveAddr + ",发货时间:" + monment(item.arrivalTime).format('YYYY-MM-DD HH:mm:ss')
            } else if (item.orderModel === '3') {
              item.orderModelStatus = '配送'
              item.orderModelName = '货物正在配送，我们将尽快送达 ' + monment(item.arrivalTime).format('YYYY-MM-DD HH:mm:ss')
            } else if (item.orderModel === '4') {
              item.orderModelStatus = '签收'
              item.orderModelName = '货物已签收,签收时间:' + monment(item.arrivalTime).format('YYYY-MM-DD HH:mm:ss') + '  签收人:' + item.orderModelName
            } else {
              item.orderModelStatus = '异常'
            }
          });
          this.steps = res.data.orderLogistics
          this.activities = res.data.orderLogistics
          this.receiveRemark = '[收货地址]' + res.data.receiveAddr
        }

      })
    },
    updateOrderStatus() {
      this.$axios.post(this.$httpUrl + '/kkl/tms/webchat/orders/updateOrderStatus', this.updateForm).then(res => res.data).then(res => {
        if (res.code == 200) {
          this.updateCenterDialogVisible = false
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.pageNum = 1;
          this.loadPost();
        } else {
          this.$message({
            message: '修改失败',
            type: 'error'
          });
        }
      })
    },
    handleClick(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val}条`)
      this.pageSize = val
      this.pageNum = 1
      this.loadPost()
    },
    handleCurrentChange(val) {
      console.log(`当前页:${val}`)
      this.pageNum = val
      this.loadPost()
    },
    resertQuery() {
      this.orderModel = '';
      this.codeQry = '';
    },
    queryList() {
      this.pageNum = 1;
      this.loadPost();
    },
    loadPost() {
      this.$axios.post(this.$httpUrl + '/kkl/tms/webchat/orders/getOrderPageList', {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        param: {
          orderModel: this.orderModel,
          code: this.codeQry,
          startTime: this.startTime,
          endTime: this.endTime,
        }
      }).then(res => res.data).then(res => {
        res.data.records.forEach(item => {
          item.trustTime = monment(item.trustTime).format('YYYY-MM-DD HH:mm:ss')
        });
        this.tableData = res.data.records
        this.total = res.data.total
      })
    }
  },
  beforeMount() {
    this.loadPost();
  }
  ,
}
</script>

<style scoped>


</style>